
import {computed, defineComponent, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";

import {reinitializeComponents} from "@/core/plugins/keenthemes";
import {useToast} from "vue-toastification";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
    name: "tariffs",
    components: {
        //AllowedIcon,
        //DisabledIcon,
        //ExclamationNotice,
    },
    setup() {
        const store = useStore();
        const toast = useToast();

        let in_progress = ref(false);

        let filter_channel_name = ref('');
        let filter_channel_category = ref('');
        let filter_channel_hd_type = ref('');

        let channels_list_analog = ref([]);
        let channels_list_digital = ref([]);

        let channels_list_analog_count = ref(0);
        let channels_list_digital_count = ref(0);

        const userData = computed(() => {
            return store.getters.currentUser;
        });

        const filterChannelByChannelName = (new_value, channels_list_default) => {
            let items = JSON.parse(JSON.stringify(channels_list_default));

            if (new_value.length >= 2) {
                let to_filter = [] as any;

                Object.entries(items).map((item) => {
                    to_filter.push(item[1]);
                });

                let result = to_filter.map((item) => {
                    let channels = item.channels.filter((item_channel) => {
                        if (item_channel.channel_name.toLowerCase().includes(new_value.toLowerCase())) {
                            return item_channel;
                        }

                        return null;
                    });

                    return {
                        category_id: item.category_id,
                        category_label: item.category_label,
                        channels: channels,
                    }
                });

                return result;
            }

            return channels_list_default;
        }

        const filterChannelByCategory = (new_value, channels_list_default) => {
            let items = JSON.parse(JSON.stringify(channels_list_default));

            if (new_value != '') {
                let to_filter = [] as any;

                Object.entries(items).map((item) => {
                    to_filter.push(item[1]);
                });

                let total_ch = 0;
                let result = to_filter.filter((item) => {
                    if (item.category_id == new_value) {
                        total_ch = item.channels.length;

                        return item;
                    }

                    return null;
                });

                if (result && result.length > 0) {
                    toast.success('Усього каналів у вибраній категорії: ' + total_ch);
                }

                return result;
            }

            return channels_list_default;
        }

        const filterChannelByHDType = (new_value, channels_list_default) => {
            let items = JSON.parse(JSON.stringify(channels_list_default));

            if (new_value != '') {
                let to_filter = [] as any;

                Object.entries(items).map((item) => {
                    to_filter.push(item[1]);
                });

                let result = to_filter.map((item) => {
                    let channels = item.channels.filter((item_channel) => {
                        if (item_channel.hd_type_id == new_value) {
                            return item_channel;
                        }

                        return null;
                    });

                    return {
                        category_id: item.category_id,
                        category_label: item.category_label,
                        channels: channels,
                    };
                });

                /*if (result && result.length > 0) {
                    toast.success('Усього каналів у вибраній категорії: ' + total_ch);
                }*/

                return result;
            }

            return channels_list_default;
        }

        //Фильтр по названию каналов
        watch(filter_channel_name, (new_value) => {
            //Filter analog channels
            channels_list_analog.value = filterChannelByChannelName(new_value, userData.value.channels_list_analog);

            //Filter digital channels
            channels_list_digital.value = filterChannelByChannelName(new_value, userData.value.channels_list_digit);
        });

        //Фильтр по категории каналов
        watch(filter_channel_category, (new_value) => {
            //Filter analog channels
            channels_list_analog.value = filterChannelByCategory(new_value, userData.value.channels_list_analog);

            //Filter digital channels
            channels_list_digital.value = filterChannelByCategory(new_value, userData.value.channels_list_digit);
        });

        //Фильтр по качеству каналов
        watch(filter_channel_hd_type, (new_value) => {
            //Filter analog channels
            channels_list_analog.value = filterChannelByHDType(new_value, userData.value.channels_list_analog);

            //Filter digital channels
            channels_list_digital.value = filterChannelByHDType(new_value, userData.value.channels_list_digit);
        });

        //Наблюдаем за переменной списка каналов и поределяем сколько каналов попало в фильтр
        watch(channels_list_analog, () => {
            channels_list_analog_count.value = getChannelsCount(channels_list_analog.value);
        });

        //Наблюдаем за переменной списка каналов и поределяем сколько каналов попало в фильтр
        watch(channels_list_digital, () => {
            channels_list_digital_count.value = getChannelsCount(channels_list_digital.value);
        });

        const getChannelsCount = (channels_list) => {
            let items = JSON.parse(JSON.stringify(channels_list));
            let count = 0;

            let to_filter = [] as any;

            Object.entries(items).map((item) => {
                to_filter.push(item[1]);
            });

            to_filter.forEach((item) => {
                count += item.channels.length;
            });

            return count;
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Канали (Аналогові / Цифрові)", ["Profile", "Tariffs"]);

            //Обязательно использовать value
            channels_list_analog.value = userData.value.channels_list_analog;
            channels_list_digital.value = userData.value.channels_list_digit;

            setCurrentPageTitle("Канали");
            nextTick(() => {
                reinitializeComponents();
            });
        });

        return {
            userData,
            in_progress,

            filter_channel_name,
            filter_channel_category,
            filter_channel_hd_type,

            channels_list_analog,
            channels_list_digital,

            channels_list_analog_count,
            channels_list_digital_count
        };
    },
});
